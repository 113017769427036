import { NgModule } from '@angular/core';

import { GoogleAddressDirective } from './common/directive/google-address.directive';
import { HideAutoCompleteDirective } from './common/directive/hide-auto-complete.directive';
import { AbbreviatePipe } from './common/pipes/abbreviate';
import { AbbreviateNumberPipe } from './common/pipes/abbreviate-number.pipe';
import { LocalDatePipe } from './common/pipes/date.pipe';
import { DiscountedAmount } from './common/pipes/discounted-amount';
import { FileSizePipe } from './common/pipes/file-size.pipe';
import { LocaleDatePipe } from './common/pipes/locale-date.pipe';
import { OrderByPipe } from './common/pipes/order-by.pipe';
import { SafeStylePipe } from './common/pipes/safe-style';
import { SafeHtmlPipe } from './common/pipes/safehtml.pipe';
import { StartingYearPipe } from './common/pipes/starting-year.pipe';
import { StripHtmlPipe } from './common/pipes/striphtml.pipe';
import { TimePipe } from './common/pipes/time.pipe';
import { TimeUnitSuffixPipe } from './common/pipes/timeunitsuffix.pipe';
import { TruncatePipe } from './common/pipes/truncate.pipe';
import { WidgetPipeModule } from './common/pipes/widget-pipe.module';

@NgModule({
    imports: [WidgetPipeModule],
    declarations: [
        LocalDatePipe,
        TimePipe,
        SafeHtmlPipe,
        StripHtmlPipe,
        TruncatePipe,
        LocaleDatePipe,
        AbbreviatePipe,
        SafeStylePipe,
        StartingYearPipe,
        FileSizePipe,
        GoogleAddressDirective,
        DiscountedAmount,
        OrderByPipe,
        HideAutoCompleteDirective,
        TimeUnitSuffixPipe,
        AbbreviateNumberPipe,
    ],
    exports: [
        GoogleAddressDirective,
        LocalDatePipe,
        TimePipe,
        SafeHtmlPipe,
        StripHtmlPipe,
        TruncatePipe,
        LocaleDatePipe,
        AbbreviatePipe,
        SafeStylePipe,
        StartingYearPipe,
        FileSizePipe,
        DiscountedAmount,
        OrderByPipe,
        HideAutoCompleteDirective,
        TimeUnitSuffixPipe,
        WidgetPipeModule,
        AbbreviateNumberPipe,
    ],
})
export class SharedModule {}
