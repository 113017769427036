import { Storage } from 'app/common/enums/storage';
import { Environment } from 'app/common/interfaces';

import { defaults } from './defaults.environment';
import { localization } from './locale.en-aus';

export const environment: Environment = {
    production: true,
    isAllowedInvalidAppSubmission: true,
    domainServer: 'dev-api.enquirytracker.net',
    apiUrl: 'https://dev-api.enquirytracker.net/',
    googleMapApiKey: 'AIzaSyCK8bRoe_woo-M3IbpWJZaNW132C8v257s',
    googleTranslateApiKey: 'AIzaSyCK8bRoe_woo-M3IbpWJZaNW132C8v257s',
    storageType: Storage.LocalStorage,
    reCaptchaSiteKey: '6LdBp60UAAAAAFybBZaEiBshGVKRexxEWObKcWOU',
    reCaptchaV2SiteKey: '6LegAkQUAAAAAOoGejQnzUhyjUInJkra0yGmR8SW',
    ...defaults,
    localization,
};
