import { DragDropModule } from '@angular/cdk/drag-drop';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeAf from '@angular/common/locales/af';
import localeInd from '@angular/common/locales/en-IN';
import localeAfExtra from '@angular/common/locales/extra/af';
import localeIndExtra from '@angular/common/locales/extra/en-IN';
import localeMsExtra from '@angular/common/locales/extra/ms';
import localeThExtra from '@angular/common/locales/extra/th';
import localeMs from '@angular/common/locales/ms';
import localeTh from '@angular/common/locales/th';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DateAdapter, MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import {
    MAT_LEGACY_CHECKBOX_DEFAULT_OPTIONS as MAT_CHECKBOX_DEFAULT_OPTIONS,
    MatLegacyCheckboxDefaultOptions as MatCheckboxDefaultOptions,
    MatLegacyCheckboxModule as MatCheckboxModule,
} from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import {
    MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS,
    MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import {
    MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
    MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions,
    MatLegacyTooltipModule as MatTooltipModule,
} from '@angular/material/legacy-tooltip';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule, TitleStrategy } from '@angular/router';
import { Router } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { NgbActiveModal, NgbModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { ngfModule } from 'angular-file';
import { environment } from 'environments/environment';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { ZingchartAngularModule } from 'zingchart-angular';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { Constants } from './common/constants';
import { PAGE_TITLE_SEPARATOR, PageHierarchyTitleStrategy } from './common/page-hierarchy-title-strategy';
import { Utils } from './common/utils';
import { CampusSwitcherComponent } from './components/campus-switcher/campus-switcher.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { OrgSwitcherComponent } from './components/org-switcher/org-switcher.component';
import { PageSpinnerComponent } from './components/page-spinner/page-spinner.component';
import { SchoolSwitcherComponent } from './components/school-switcher/school-switcher.component';
import { ViewSwitcherComponent } from './components/view-switcher/view-switcher.component';
import { CustomReuseStrategy } from './custom-reuse-strategy';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { OrgLayoutComponent } from './layouts/org/org-layout.component';
import { ErrorService } from './services/error.service';
import { LocaleService } from './services/locale.service';
import { PaginatorService } from './services/paginator.service';
import { StorageService } from './services/storage.service';
import { SharedModule } from './shared.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';

registerLocaleData(localeAf, localeAfExtra);
registerLocaleData(localeMs, localeMsExtra);
registerLocaleData(localeTh, localeThExtra);
registerLocaleData(localeInd, localeIndExtra);

export function tokenGetter() {
    return Utils.getToken();
}

export function initStorage(storageService: StorageService) {
    return () => storageService.init();
}

// required for AOT compilation
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
    accountUrl = environment.brand.zendeskAccountUrl;
    callback(zE) {
        zE('webWidget', 'hide');
    }
}

export const customTooltipOptions: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 1500,
    disableTooltipInteractivity: true,
};

@NgModule({
    exports: [
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        DragDropModule,
        NgbProgressbarModule,
    ],
})
export class MaterialModule {}

@NgModule({
    imports: [
        MatMomentDateModule, // prevent having multiple instaces of the DateAdapter service
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(AppRoutes),
        MaterialModule,
        SidebarComponent,
        NavbarComponent,
        ViewSwitcherComponent,
        SchoolSwitcherComponent,
        CampusSwitcherComponent,
        OrgSwitcherComponent,
        NotificationsComponent,
        FooterModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
                allowedDomains: [Constants.domainServer],
                authScheme: '',
            },
        }),
        NgxMatTimepickerModule,
        ngfModule,
        NgxDaterangepickerMd.forRoot(),
        SharedModule,
        NgxMatSelectSearchModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            useDefaultLang: false,
        }),
        NgbModule,
        AkitaNgDevtools.forRoot(),
        ZingchartAngularModule,
        NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    ],
    exports: [RouterModule],
    declarations: [AppComponent, AdminLayoutComponent, OrgLayoutComponent, AuthLayoutComponent, PageSpinnerComponent],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initStorage,
            deps: [StorageService],
            multi: true,
        },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: LocaleService.getCurrentLocale() },
        { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } as MatCheckboxDefaultOptions },
        { provide: ErrorHandler, useClass: ErrorService },
        NgbActiveModal,
        {
            provide: RouteReuseStrategy,
            useClass: CustomReuseStrategy,
        },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true, width: Constants.matDialogLg } },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipOptions },
        { provide: MatPaginatorIntl, useClass: PaginatorService },
        { provide: TitleStrategy, useClass: PageHierarchyTitleStrategy },
        { provide: PAGE_TITLE_SEPARATOR, useValue: ' - ' },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
