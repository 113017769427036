import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    public errorEvent: Subject<Error> = new Subject<any>();

    constructor(private httpService: HttpService) {}

    handleError(err: Error) {
        console.error('ErrorService.handleError: ', err);
        const error = {
            name: err.name,
            message: err.message,
            stack: err.stack,
        };
        const clientURL = location.href;
        return this.httpService.post('client-log/debug', { error, clientURL }).catch(() => {
            return;
        });
    }
}
