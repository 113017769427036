import '@angular/compiler';
// Needs for bootstrap using '@angular/platform-browser-dynamic' or '@angular/platform-server'
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableAkitaProdMode, persistState } from '@datorama/akita';
import * as Sentry from '@sentry/angular';
import { AppModule } from 'app/app.module';
import { Constants } from 'app/common/constants';
import { environment } from 'environments/environment';

if (environment.production) {
    Sentry.init({
        dsn: 'https://4853a74d0a81d3b979b8df6b6fb60ba6@o4507770116177920.ingest.de.sentry.io/4507770118930512',
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.browserProfilingIntegration()],
        release: Constants.version,
        environment: environment.domainServer,
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/*.enquirytracker.net/, /^https:\/\/*.connect.schoolmint.com/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        // Set profilesSampleRate to 1.0 to profile every transaction.
        // Since profilesSampleRate is relative to tracesSampleRate,
        // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
        // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
        // results in 25% of transactions being profiled (0.5*0.5=0.25)
        profilesSampleRate: 1.0,
    });

    enableProdMode();
    enableAkitaProdMode();
}

export const persistStorage = persistState({
    include: ['school'],
    key: 'ETStore',
});

export const chartPersistStorage = persistState({
    include: ['chart'],
    key: 'ETChartStore',
});

export const transferPersistStorage = persistState({
    include: ['transfer'],
    key: 'ETTransferStore',
});

export const mapLayerStorage = persistState({
    include: ['mapLayer'],
    key: 'ETMapLayerStore',
});

export const googleTranslatePersistStorage = persistState({
    include: ['googleTranslate'],
    key: 'ETGoogleTranslateStore',
});

const providers = [
    { provide: 'persistStorage', useValue: persistStorage },
    { provide: 'chartPersistStorage', useValue: chartPersistStorage },
    { provide: 'transferPersistStorage', useValue: transferPersistStorage },
    { provide: 'mapLayerStorage', useValue: mapLayerStorage },
    { provide: 'googleTranslate', useValue: googleTranslatePersistStorage },
];

platformBrowserDynamic(providers).bootstrapModule(AppModule).catch(console.error);
